<template>
    <v-container fluid class="page-wrapper">
        <v-navigation-drawer
            class="menu-visit"
            v-if="!isLoading"
            v-model="drawer"
            temporary
          >
            <MenuVisit :forms="visit" @closeVisitMenu="closeVisitMenu"/>
        </v-navigation-drawer>
        <v-row class="page-header">
            <v-col cols="12" class="d-flex align-items-top">
                <div @click.stop="drawer = !drawer" class="mt-3 mr-1"><div class="px-2 py-1 fw-15x cursor-pointer"><i class="edt-filters"></i></div></div>
                <HeaderSite
                    :title="this.currentItemName == null ? $t('visits.singleTitle') : this.currentItemName"
                />
            </v-col>
        </v-row>
        <div class="page-content" ref="container">
            <div v-if="isLoading">
                <Loader />
            </div>
            <div v-else ref="containerInner" :class="(this.modeEditionVisit == true && isStarted()) ? 'mode-edition' : ''">
                <v-row v-if="currentFormId === null">
                    <v-col cols="12">
                        <VisitActions />
                        <VisitInfo />
                        <div class="text-center"><v-btn class="primary">{{ $t('visits.btnRealestatePage') }}</v-btn></div>
                        <hr class="my-2"/>
                        <div class="visit-item-stat">
                            <Progression v-if="progression[visitId.toString()] !== null" :progression="progression[visitId.toString()]" :formId="null" />
                            <Note v-if="notation[visitId.toString()] !== null" :notation="notation[visitId.toString()]" :formId="null" />
                        </div>
                    </v-col>
                    <v-col cols="12" class="mt-2">
                        <h2>{{ $t('visits.forms') }}</h2>
                    </v-col>
                    <FormItem v-for="form in visit" :key="form.id" :form="form" :notation="notation[visitId.toString()]" :progression="progression[visitId.toString()]" />
                </v-row>
                <v-row v-else-if="this.sectionList != null && Object.keys(this.sectionList).length > 0">
                    <SectionItem v-for="section in sectionList" :key="section.id" :section="section" :formId="currentFormId" :sectionArbo="setSectionArbo(section.id)" @visibilitySection="visibilitySection" @updateLabelSection="updateLabelSection" @duplicateSection="duplicateSection" />
                </v-row>
                <v-row v-if="this.fieldList != null && Object.keys(this.fieldList).length > 0">
                    <v-col cols="12">
                        <v-card class="card">
                            <FieldItem v-for="field in fieldList" :key="field.id" :field="field" @updateJson="updateJson" @visibilityField="visibilityField" @updateCommentField="updateCommentField" @updateAlertField="updateAlertField" @addFiles="addFiles" @deleteFile="deleteFile" />
                        </v-card>
                    </v-col>
                </v-row>
                <v-row v-if="currentSectionsId !== null">
                    <v-col cols="12">
                        <hr v-if="this.modeEditionVisit === true || this.sectionComment.addComment === true" class="my-2"/>
                        <Comment :comment="this.sectionComment" @updateCommentSection="updateCommentSection" type="section" />
                    </v-col>
                </v-row>
                <v-row v-else-if="this.sectionList != null && Object.keys(this.sectionList).length > 0">
                    <v-col cols="12">
                        <PrevNext />
                    </v-col>
                </v-row>
                <v-btn
                    v-if="isStarted()"
                    class="v-btn--round btn-round-big pa-0 btn-visit-edit"
                    :class="this.modeEditionVisit == false ? 'bg-off' : 'bg-blue'"
                    @click="switchModeEdition"
                >
                    <i class="edt-wrench fw-15x"></i>
                </v-btn>
                <v-btn
                    v-if="currentSectionsId !== null"
                    class="v-btn--round btn-round-big pa-0 btn-section-complete"
                    :class="this.sectionState == false ? 'bg-off' : 'bg-green'"
                    @click="switchSectionState()"
                >
                    <i class="edt-check fw-15x"></i>
                </v-btn>
            </div>
        </div>
    </v-container>
</template>

<script>
/*    import localForage from 'localforage'*/
    import dbService from '@/services/localDB';
    import { GlobalEventEmitter } from '@/plugins/GlobalEventEmitter';
    import HeaderSite from "@/components/HeaderSite";
    import { mapGetters, mapActions } from "vuex";
    import Loader from "@/components/Loader";
    import MenuVisit from '@/components/visit/menu/MenuVisit.vue';
    import FormItem from '@/components/visit/detail/FormItem.vue';
    import SectionItem from '@/components/visit/detail/SectionItem.vue';
    import FieldItem from '@/components/visit/detail/FieldItem.vue';
    import VisitInfo from '@/components/visit/detail/VisitInfo.vue';
    import VisitActions from '@/components/visit/detail/VisitActions.vue';
    import PrevNext from '@/components/visit/detail/PrevNext.vue';
    import Comment from '@/components/visit/detail/Comment.vue';
    import Note from "@/components/visit/detail/Note";
    import Progression from "@/components/visit/detail/Progression";

    export default {
        name: "VisitDetail",
        components: { Loader, HeaderSite, MenuVisit, FormItem, SectionItem, FieldItem, VisitInfo, VisitActions, PrevNext, Comment, Note, Progression },
        data() {
            return {
                visitId: null,
                isLoading: true,
                drawer: null,
                sectionList: [],
                fieldList: null,
                arbo : [],
                sectionState: false,
                currentItemId: null,
                currentItemName: null,
                tmpVisit: null,
                sectionComment: {
                    addComment: false,
                    comment: ''
                }
            };
        },
        watch: {
            sectionList: {
                immediate: true,
                handler(newVal) {
                    this.sectionList = newVal;
                }
            }
        },
        /*beforeRouteLeave(route, redirect, next) {
            if(window.confirm("Voulez-vous vraiment quitter l'édition du formulaire ?")){
                next()
            }
        },*/
        computed: {
            ...mapGetters( 'visitStore', {visit: 'StateVisit', currentSectionsId: 'StateCurrentSectionsId', currentStartedVisit: 'StateCurrentStartedVisit', modeEditionVisit: 'StateModeEditionVisit', sectionsIndex : 'StateSectionsIndex', currentFormId: 'StateCurrentFormId', notation: 'StateVisitsFormsNotation', progression: 'StateVisitsFormsProgression' } ),
            sectionsIndexCount() {
                return Object.keys(this.sectionsIndex).length;
            }
        },
        methods: {
            ...mapActions('visitStore', ["GetVisitForms", "UpdateJson", "UpdateIndex", "SwitchModeEdition", "SetCurrentFormId", "AddFilesToSend", "RemoveFilesToSend"]),
            isStarted() {
                if(parseInt(this.currentStartedVisit) === parseInt(this.$route.params.id)) {
                    return true;
                }
                return false;
            },
            switchModeEdition() {
                let edition = this.modeEditionVisit === true ? false: true;
                this.SwitchModeEdition(edition);
            },
            closeVisitMenu() {
                this.drawer = false;
            },
            /* Liste des sections parentes */
            setSectionArbo(sectionId) {
                return this.currentSectionsId === null ? sectionId : this.currentSectionsId + ',' + sectionId;
            },
            updateTmpJson() {
                this.tmpVisit = JSON.parse(JSON.stringify(this.visit));
            },
            changeVisitView() {
                this.updateTmpJson();
                /* On ferme le menu s'il est ouvert */
                this.closeVisitMenu();
                /* Tab de correspondance "index/position" -> id de section */
                /*let indexSections = this.sectionsIndex;*/
                /* Si on a déjà sélectionné un form */
                if(this.currentFormId !== null && this.sectionsIndex !== null) {
                    /* Id du form en cours */
                    /*this.currentFormId = this.currentFormId;*/
                    /* Si on a déjà sélectionné une section */
                    if(this.currentSectionsId !== null) {
                        /* Arbo des id de section */
                        /*this.currentSectionsId = this.$store.getters.StateCurrentSectionsId + '';*/
                        let sectionsId = (!Number.isInteger(this.currentSectionsId) && this.currentSectionsId.includes(",")) ? this.currentSectionsId.split(',') : [this.currentSectionsId];
                        /* liste des sections de 1er niveau du form sélectionné */
                        /*this.sectionList = this.visit[this.$store.getters.StateCurrentFormId].sections;*/
                        /* Section de 1er niveau du form */
                        let tmpSectionList = this.tmpVisit[this.currentFormId].sections;
                        let tmpFields = null;
                        let tmpSectionId = null;
                        let tmpSectionName = null;
                        let tmpSectionComplete = false;
                        let tmpSectionComment = {addComment: false, comment: ''};
                        
                        /* On boucle sur chaque niveau de section */
                        sectionsId.forEach(id => {
                            let tmpIndex = this.sectionsIndex[id];
                            tmpSectionId = tmpSectionList[tmpIndex].id;
                            tmpSectionName = tmpSectionList[tmpIndex].name;
                            tmpSectionComplete = tmpSectionList[tmpIndex].complete != true ? false : true;
                            tmpSectionComment.addComment = tmpSectionList[tmpIndex].add_comment != true ? false : true;
                            tmpSectionComment.comment = tmpSectionList[tmpIndex].comment;
                            tmpFields = tmpSectionList[tmpIndex].fields;
                            tmpSectionList = tmpSectionList[tmpIndex].sections;
                        });
                            /* On set les infos de la section de dernier niveau qui a été sélectionné */
                            this.sectionState = tmpSectionComplete;
                            this.sectionComment = tmpSectionComment;
                            this.currentItemId = tmpSectionId;
                            this.currentItemName = tmpSectionName;
                            this.sectionList = tmpSectionList;
                            this.fieldList = tmpFields;
                    } else {
                        /* Seul le form a été sélectionné */
                        this.sectionList = this.tmpVisit[this.currentFormId].sections;
                        this.fieldList = null;
                        this.currentItemId = this.tmpVisit[this.currentFormId].form_id;
                        this.currentItemName = this.tmpVisit[this.currentFormId].form_name;
                    }
                } else {
                    /* Aucun form n'a été sélectionné */
                    this.sectionList = [];
                    this.SetCurrentFormId(null);
                    this.fieldList = null;
                }
            },
            /* [FIELDS] */
            /* Update le champ modifié dans le json du localStorage */
            updateJson(fieldId, value, id = null, note = null) {
                Object.values(this.fieldList).forEach(item => {
                    if(item.id === fieldId) {
                        item.field_value = JSON.stringify(value);
                        if(id !== null) {
                            item.field_value_id = JSON.stringify(id);
                        }
                        if(note !== null) {
                            item.field_value_note = note;
                        }
                    }
                });

                this.updateSectionFields();
                if(this.tmpVisit !== null) {
                    this.UpdateJson([this.$route.params.id, this.tmpVisit]);
                }
            },
            /* Maj le json avec le champ modifié */
            updateSectionFields() {
                let tmpCurrentSectionsId = this.currentSectionsId + '';
                let sectionsId = tmpCurrentSectionsId.includes(",") ? tmpCurrentSectionsId.split(',') : [tmpCurrentSectionsId];

                /*let indexSections = this.sectionsIndex;*/
                let i = 0;
                    let tmpJson = this.tmpVisit[this.currentFormId].sections;
                    // Parcourir chaque ID dans la liste
                    for (let id of sectionsId) {
                        let tmpIndex =  this.sectionsIndex[id];
                        if (i < sectionsId.length) {
                            tmpJson = tmpJson[tmpIndex]['sections'];
                        } else {
                            tmpJson = tmpJson[tmpIndex]['fields'];
                        }
                        i++;
                    }
                    tmpJson = this.fieldList;
            },/* Update le champ modifié dans le json du localStorage */
            updateCommentField(options) {
                Object.values(this.fieldList).forEach(item => {
                    if(item.id === options.fieldId) {
                        item.add_comment = options.comment.addComment;
                        item.comment = options.comment.comment;
                    }
                });

                this.updateJsonFieldVisibility();
                this.UpdateJson([this.$route.params.id, this.tmpVisit]);
            },
            updateAlertField(options) {
                Object.values(this.fieldList).forEach(item => {
                    if(item.id === options.fieldId) {
                        item.alert_flag = options.alert;
                    }
                });

                this.updateJsonFieldVisibility();
                this.UpdateJson([this.$route.params.id, this.tmpVisit]);
            },
            visibilityField(options) {
                Object.values(this.fieldList).forEach(item => {
                    if(item.id === options.fieldId) {
                        item.is_hidden = options.hidden;
                    }
                });

                this.updateJsonFieldVisibility();
                this.UpdateJson([this.$route.params.id, this.tmpVisit]);
            },
            /* Maj le json avec le champ modifié */
            updateJsonFieldVisibility() {
                let tmpCurrentSectionsId = this.currentSectionsId + '';
                let sectionsId = tmpCurrentSectionsId.includes(",") ? tmpCurrentSectionsId.split(',') : [tmpCurrentSectionsId];

                /*let indexSections = this.sectionsIndex;*/
                let i = 0;
                let tmpJson = this.tmpVisit[this.currentFormId].sections;
                // Parcourir chaque ID dans la liste
                for (let id of sectionsId) {
                    let tmpIndex =  this.sectionsIndex[id];
                    if (i < sectionsId.length) {
                        tmpJson = tmpJson[tmpIndex]['sections'];
                    } else {
                        tmpJson = tmpJson[tmpIndex]['fields'];
                    }
                    i++;
                }
                tmpJson = this.fieldList;
            },
            generateUniqueFileName(file) {
                const timestamp = Date.now(); // Récupère le timestamp actuel
                const fileExtension = file.name.split('.').pop(); // Récupère l'extension du fichier
                const fileNameWithoutExtension = file.name.split('.').slice(0, -1).join('.');
                fileNameWithoutExtension.trim();
                const uniqueFileName = `${fileNameWithoutExtension}_${timestamp}.${fileExtension}`;
                return uniqueFileName;
            },
            addFiles(id, files) {         
                Object.values(this.fieldList).forEach(item => {
                    if(item.id === id) {
                        /* On ajoute les fichiers au json */
                        files.forEach(file => {
                            const name = this.generateUniqueFileName(file);
                            const renamedFile = new File([file], name, { type: file.type });
                            item.files.push({
                                name: name,
                                originalName: file.name,
                                size: file.size,
                                type: file.type,
                                new: true,
                                deleted: false
                            });
                            /* On sauvegarde les fichiers dans l'indexedDB */
                            try {
                                const db = dbService(this.$route.params.id);
                                db.setItem(name, renamedFile);
                                console.log('File successfully saved!');
                            } catch (error) {
                                console.error('Error saving file:', error);
                            }
                            /* On ajoute le fichier à la liste des fichiers à envoyer */
                            this.AddFilesToSend([this.$route.params.id, name, file.name, true]);
                        });
                    }
                });
                /*this.updateJsonFieldVisibility();*/
                this.UpdateJson([this.$route.params.id, this.tmpVisit]);
            },
            deleteFile(id, fileIndex, fileName) {                
                Object.values(this.fieldList).forEach(item => {
                    if(item.id === id) {
                        item.files.splice(fileIndex, 1);
                        try {
                            const db = dbService(this.$route.params.id); 
                            db.removeItem(fileName);
                            this.RemoveFilesToSend([this.$route.params.id, fileName]);
                            console.log('File successfully removed from localForage!');
                        } catch (error) {
                            console.error('Error removing file:', error);
                        }
                    }
                });
                /*this.updateJsonFieldVisibility();*/
                this.UpdateJson([this.$route.params.id, this.tmpVisit]);
            },
            /* [SECTIONS] */
            /* Maj le json avec la visibilité de la section modifiée */
            updateJsonSectionData(sectionArbo, type, data) {
                let tmpCurrentSectionsId = sectionArbo !== null ? sectionArbo + '' : this.currentSectionsId + '';
                let sectionsId = tmpCurrentSectionsId.includes(",") ? tmpCurrentSectionsId.split(',') : [tmpCurrentSectionsId];

                /*let indexSections = this.sectionsIndex;*/
                let tmpJson = this.tmpVisit[this.currentFormId].sections;
                // Parcourir chaque ID dans la liste
                let i = 1;
                for (let id of sectionsId) {
                    let tmpIndex =  this.sectionsIndex[id];
                    if (i < sectionsId.length) {
                        tmpJson = tmpJson[tmpIndex]['sections'];
                    } else {
                        tmpJson = tmpJson[tmpIndex];
                    }
                    i++;
                }
                if(type == 'is_hidden') {
                    tmpJson.is_hidden = data;
                }else if(type == 'name') {
                    tmpJson.name = data;
                }else if(type == 'comment') {
                    tmpJson.add_comment = this.sectionComment.addComment;
                    tmpJson.comment = this.sectionComment.comment;
                } else if(type == 'complete') {
                    tmpJson.complete = this.sectionState;
                }
            },
            /* Update la visibilité de la section dans le json du localStorage */
            visibilitySection(options) {
                this.updateJsonSectionData(options.sections, 'is_hidden', options.hidden);
                this.UpdateJson([this.$route.params.id, this.tmpVisit]);
            },
            /* Update le label de la section dans le json du localStorage */
            updateLabelSection(options) {
                this.updateJsonSectionData(options.sections, 'name', options.label);
                this.UpdateJson([this.$route.params.id, this.tmpVisit]);
            },
            updateCommentSection(itemComment) {
                this.sectionComment = itemComment;
                this.updateJsonSectionData(null, 'comment', null);
                this.UpdateJson([this.$route.params.id, this.tmpVisit]);
            },
            switchSectionState() {
                this.sectionState = !this.sectionState;
                this.updateJsonSectionData(null, 'complete', null);
                this.UpdateJson([this.$route.params.id, this.tmpVisit]);
            },

            /* Duplique la section dans le json du localStorage */
            duplicateSection(options) {
                this.updateJsonSectionDuplicate(options.sections, options.label);
                this.UpdateJson([this.$route.params.id, this.tmpVisit]).then(() => {
                    this.updateTmpJson();
                });
            },
            /* Maj le json de la section ajoutée */
            updateJsonSectionDuplicate(sectionArbo, label) {
                let tmpCurrentSectionsId = sectionArbo + ''; /*this.$store.getters.StateCurrentSectionsId + '';*/
                let sectionsId = tmpCurrentSectionsId.includes(",") ? tmpCurrentSectionsId.split(',') : [tmpCurrentSectionsId];

                /*let indexSections = this.sectionsIndex;*/
                /*let updatedJsonVisit = { ...this.visit };*/
                let tmpJson = this.tmpVisit[this.currentFormId].sections;
                // Parcourir chaque ID dans la liste
                let i = 1;
                for (let id of sectionsId) {
                    let tmpIndex =  this.sectionsIndex[id];
                    if (i < sectionsId.length) {
                        tmpJson = tmpJson[tmpIndex]['sections'];
                    } else {
                        tmpJson = tmpJson[tmpIndex];
                    }
                    i++;
                }
                const elementToDuplicate = JSON.parse(JSON.stringify(tmpJson));

                const existingKeys = Object.keys(this.sectionsIndex).map(Number);
                const existingValues = Object.values(this.sectionsIndex);
                const newKey = this.findUniqueKey(existingKeys);
                const newValue = this.findUniqueValue(existingValues);
                this.UpdateIndex([this.$route.params.id, newKey, newValue]);

                const duplicatedSection = JSON.parse(JSON.stringify(elementToDuplicate));
                duplicatedSection.id = newKey;
                duplicatedSection.name = label;
                duplicatedSection.section_answer_id = null;
                if(Object.keys(duplicatedSection['fields']) && Object.keys(duplicatedSection['fields']).length > 0) {
                    duplicatedSection['fields'] = this.updateDuplicatedFields(duplicatedSection['fields']);
                }
                if(Object.keys(duplicatedSection['sections']) && Object.keys(duplicatedSection['sections']).length > 0) {
                    duplicatedSection['sections'] = this.updateDuplicatedChildren(duplicatedSection['sections']);
                }

                /* Set l'élément dupliqué dans la bonne section */
                tmpJson = this.tmpVisit[this.currentFormId].sections;
                let j = 1;
                for (let id of sectionsId) {
                    let tmpIndex =  this.sectionsIndex[id];
                    if (j < sectionsId.length) {
                        tmpJson = tmpJson[tmpIndex]['sections'];
                    } else {
                        tmpJson[newValue] = duplicatedSection;
                    }
                    j++;
                }
                tmpJson = null;
                /*return tmpJson;*/
            },
            updateDuplicatedChildren(subSections) {
                const newSubSections = {};
                for (const value of Object.values(subSections)) {
                    const existingKeys = Object.keys(this.sectionsIndex).map(Number);
                    const existingValues = Object.values(this.sectionsIndex);
                    const newKey = this.findUniqueKey(existingKeys);
                    const newValue = this.findUniqueValue(existingValues);
                    this.UpdateIndex([this.$route.params.id, newKey, newValue]);

                    const duplicatedSection = JSON.parse(JSON.stringify(value));
                    duplicatedSection.id = newKey;
                    duplicatedSection.section_answer_id = null;
                    if(Object.keys(duplicatedSection['fields']).length > 0) {
                        duplicatedSection['fields'] = this.updateDuplicatedFields(duplicatedSection['fields']);
                    }
                    if(Object.keys(duplicatedSection['sections']).length > 0) {
                        duplicatedSection['sections'] = this.updateDuplicatedChildren(duplicatedSection['sections']);
                    }
                    newSubSections[newValue] = duplicatedSection;
                }
                return newSubSections;
            },
            updateDuplicatedFields(fields) {
                for (const value of Object.values(fields)) {
                    value.id = this.generateUniqueId();
                    value.field_answer_id = null;
                }
                return fields;
            },
            /* [OTHERS] */
            findUniqueKey(existingKeys, start = 1) {
                let newKey = start;
                while (existingKeys.includes(newKey)) {
                    newKey++;
                }
                return newKey;
            },
            findUniqueValue(existingValues) {
                let newValue = 1;
                while (existingValues.includes(newValue)) {
                    newValue++;
                }
                return newValue;
            },
            generateUniqueId() {
                return Date.now().toString(36) + Math.random().toString(36).substr(2, 9);
            }
        },
        created() {
            this.visitId = this.$route.params.id;
            GlobalEventEmitter.$on('visitNavigation', () => {
                this.changeVisitView();
            });
            GlobalEventEmitter.$on('visibilitySection', (options) => {
                this.visibilitySection(options);
            });
            /* On récupère les info de la visite (Sf ou localstorage et on les affiche) */
            this.GetVisitForms(this.$route.params.id).then(() => {
                /*this.visit = res;*/
                this.changeVisitView();
                this.isLoading = false;
            });
        },
    };
</script>