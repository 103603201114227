import axios from 'axios'
import Vue from 'vue'
import localForage from 'localforage'

const state = {
    visits: null,
    visit: null,
    visitInfos: null,
    sectionsIndex: null,
    mine: true,
    currentFormId: null,
    currentSectionsId: null,
    currentStartedVisit: null,
    seeHidden: false,
    modeEditionVisit: false,
    filesToSend: null,
    visitsFormsNotation: [],
    visitsFormsProgression: [],
}

const getters = {
    StateVisits: state => state.visits,
    StateVisit: state => state.visit,
    StateVisitInfos: state => state.visitInfos,
    StateSectionsIndex: state => state.sectionsIndex,
    StateMine: state => state.mine,
    StateCurrentFormId: state => state.currentFormId,
    StateCurrentSectionsId: state => state.currentSectionsId,
    StateCurrentStartedVisit: state => state.currentStartedVisit,
    StateSeeHidden: state => state.seeHidden,
    StateModeEditionVisit: state => state.modeEditionVisit,
    StateFilesToSend: state => state.filesToSend,
    StateVisitsFormsNotation: state => state.visitsFormsNotation,
    StateVisitsFormsProgression: state => state.visitsFormsProgression,
}

const actions = {
    async GetVisits({ commit, rootState }) { /* Liste des visites */
        let token = rootState.auth.userToken;
        let mine = rootState.visitStore.mine;
        let url = '/api/visits?assignedToUser=' + mine;

        return await axios.get(url, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
          .then(function (res) {
              commit("StateVisits", res.data)
          })
          .catch(function (error) {
              Vue.prototype.handleError(error);
          })
    },
    async GetVisitForms({ dispatch, commit, rootState }, id) { /* Forms json d'une visite */
        if (localStorage.getItem('visit-' + id) === null || localStorage.getItem('index-visit-' + id) === null) {
            let token = rootState.auth.userToken;
            return await axios.get('/api/visit/forms/' + id,
                { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
                    .then(async function (res) {
                        commit("StateCurrentFormId", null);
                        commit("StateCurrentSectionsId", null);
                        commit("StateVisit", res.data[0]);
                        commit("StateSectionsIndex", res.data[1]);
                        await dispatch('formNotation', id);
                        await dispatch('formProgression', id);
                    })
                .catch(function (error) {
                    Vue.prototype.handleError(error);
                })
        }else {
            let visitForms = JSON.parse(localStorage.getItem('visit-'+id));
            let indexVisitForms = JSON.parse(localStorage.getItem('index-visit-'+id));
            commit("StateCurrentFormId", null);
            commit("StateCurrentSectionsId", null);
            commit("StateVisit", visitForms);
            commit("StateSectionsIndex", indexVisitForms);
            await dispatch('formNotation', id);
            await dispatch('formProgression', id);
        }
        if (localStorage.getItem('files-to-send-visit-' + id) !== null) {
            const jsonFilesToSend = localStorage.getItem('files-to-send-visit-' + id);
            const filesArray = jsonFilesToSend ? JSON.parse(jsonFilesToSend) : {};
            commit("StateFilesToSend", filesArray);
        } else {            
            commit("StateFilesToSend", []);
        }
    },
    async GetVisitInfos({ commit, rootState }, id) { /* Infos de la visite */
        if (localStorage.getItem('infos-visit-' + id) === null) {
            //let objetJSONStocke = JSON.parse(localStorage.getItem('visit-'+id));
            let token = rootState.auth.userToken;
            return await axios.get('/api/visit/informations/' + id,
                { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
                .then(function (res) {
                    commit("StateVisitInfos", res.data);
                    return res.data;
                })
                .catch(function (error) {
                    Vue.prototype.handleError(error);
                })
        }else {
            let visitInfos = JSON.parse(localStorage.getItem('infos-visit-'+id));
            commit("StateVisitInfos", visitInfos);
            return visitInfos;
        }
    },
    SwitchSeeHidden({ commit }, state) {
        commit("StateSeeHidden", state);
    },
    SwitchModeEdition({ commit }, state) {
        commit("StateModeEditionVisit", state);
    },
    SwitchVisitsList({ commit }, state) {
        commit("StateMine", state);
    },
    SetCurrentFormId({ commit }, formId) {
        commit("StateCurrentFormId", formId);
        commit("StateCurrentSectionsId", null);
    },
    SetCurrentSectionsId({ commit }, data) {
        commit("StateCurrentFormId", data[0]);
        commit("StateCurrentSectionsId", data[1]);
    },
    UpdateJson({ commit, dispatch }, data) {
        localStorage.setItem('visit-' + data[0], JSON.stringify(data[1]));
        commit("StateVisit", data[1]);        
        dispatch('formNotation', data[0]);
        dispatch('formProgression', data[0]);
    },
    UpdateIndex({ commit }, data) {
        let newIndex = JSON.parse(localStorage.getItem('index-visit-' + data[0]));
        Vue.set(newIndex, data[1], data[2]);
        localStorage.setItem('index-visit-' + data[0], JSON.stringify(newIndex));
        commit("StateSectionsIndex", newIndex);
    },
    async StartVisit({ commit, rootState }, id) {
        let token = rootState.auth.userToken;
        return await axios.post('/api/visit/state/start/' + id ,
            { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
            .then(function () {
                localStorage.setItem('visit-'+id, JSON.stringify(rootState.visitStore.visit));
                localStorage.setItem('index-visit-' + id, JSON.stringify(rootState.visitStore.sectionsIndex));
                localStorage.setItem('infos-visit-' + id, JSON.stringify(rootState.visitStore.visitInfos));

                /* Pour les fichiers utilisation de la lib localForage. A voir si on utilise cette lib pour stocker le reste des données de la visite. */
                /*localForage.createInstance({
                    name        : 'smart',
                    storeName   : 'visit-'+id
                });*/
                commit("StateCurrentStartedVisit", id);
            })
            .catch(function (error) {
                Vue.prototype.handleError(error);
            })
    },
    async SendVisit({ dispatch, commit, rootState }, data) {
        let token = rootState.auth.userToken;
        let visitJson = JSON.parse( localStorage.getItem('visit-' + data[0]));
        let visitId = data[0];

        return await axios.post('/api/visit/state/' + data[1] + '/' + visitId , {'visitData': visitJson},
            { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
            .then(function () {
                commit("StateCurrentStartedVisit", null);
                localStorage.removeItem('visit-' + visitId);
                localStorage.removeItem('index-visit-' + visitId);
                localStorage.removeItem('infos-visit-' + visitId);
                dispatch('UploadFiles', visitId);
            })
            .catch(function (error) {
                Vue.prototype.handleError(error);
            })
           
    },
    async AssignVisit({ rootState }, data) {
        let token = rootState.auth.userToken;
        return await axios.put('/api/visit/assign/' + data[0],
            { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
            .then(function () {
                localStorage.removeItem('infos-visit-' + data[0]);
                this.GetVisitInfos(data[0]);
            })
            .catch(function (error) {
                Vue.prototype.handleError(error);
            })
    },
    async UploadFiles({ dispatch, rootState }, visitId) {
        let token = rootState.auth.userToken;
        const db = localForage.createInstance({
            name: 'smart',
            storeName: `item-` + visitId  // Store dynamique basé sur l'id
        });
        
        const jsonFilesToSend = localStorage.getItem('files-to-send-visit-' + visitId);
        const filesToSend = jsonFilesToSend ? JSON.parse(jsonFilesToSend) : {};

        const promises = [];
        await db.iterate(function(value, key, iterationNumber) {
            if(filesToSend[key] && filesToSend[key].new === true && filesToSend[key].send === false) {
                const formData = new FormData();
                formData.append('file', value);
                const requestPromise = axios.post('/api/visit/files/' + visitId, formData,
                { 
                    headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + token },
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        console.log(iterationNumber + `Upload progress : ${percentCompleted}%`);
                    } 
                })
                .then(function () {
                    dispatch('ModifyFilesToSend', [visitId, key, false, true]);
                })
                .catch(function (error) {
                    Vue.prototype.handleError(error);
                })
                promises.push(requestPromise);
            }
        });

        await Promise.all(promises);
    },
    AddFilesToSend({commit}, data) {
        const jsonFilesToSend = localStorage.getItem('files-to-send-visit-' + data[0]);
        const filesArray = jsonFilesToSend ? JSON.parse(jsonFilesToSend) : {};
        filesArray[data[1]] = {
            name: data[2],
            new: data[3],
            send: false
          };

        localStorage.setItem('files-to-send-visit-' + data[0], JSON.stringify(filesArray));
        commit("StateFilesToSend", filesArray);
    },
    ModifyFilesToSend({commit}, data) {
        const jsonFilesToSend = localStorage.getItem('files-to-send-visit-' + data[0]);
        const filesArray = jsonFilesToSend ? JSON.parse(jsonFilesToSend) : {};
        
        if (filesArray[data[1]]) {
            filesArray[data[1]].new = data[2];
            filesArray[data[1]].send = data[3];
        }

        localStorage.setItem('files-to-send-visit-' + data[0], JSON.stringify(filesArray));
        commit("StateFilesToSend", filesArray);
    },
    RemoveFilesToSend({commit},data) {
        const jsonFilesToSend = localStorage.getItem('files-to-send-visit-' + data[0]);
        const filesArray = jsonFilesToSend ? JSON.parse(jsonFilesToSend) : {};

        delete filesArray[data[1]];
        
        localStorage.setItem('files-to-send-visit-' + data[0], JSON.stringify(filesArray));
        commit("StateFilesToSend", filesArray);
    },
    async formNotation({commit, dispatch, rootState}, visitId) {
        let notation = rootState.visitStore.visitsFormsNotation;
        let visitJson = rootState.visitStore.visit;
        notation[visitId.toString()] = [];
        for (let key in visitJson) {
            notation[visitId.toString()][visitJson[key].form_id.toString()] = [];
            commit("StateVisitsFormsNotation", notation);
            let data = visitJson[key].sections;
            let formId = visitJson[key].form_id;
            await dispatch('getNotNullNotes', {data, visitId, formId});
        }
    },
    async getNotNullNotes({dispatch}, {data, visitId, formId}) {
        if (typeof data === 'object' && data !== null) {
            for (const [, section] of Object.entries(data)) {
                if (typeof section.fields === 'object' && section.fields !== null) {
                    for (const [,field] of Object.entries(section.fields)) {
                        if (typeof field.field_value_note !== 'undefined' && field.field_value_note !== null) {
                            let note = field.field_value_note;
                            await dispatch('updateNote', {visitId, formId, note});
                        }
                    }
                }
                
                if (typeof section.sections === 'object' && section.sections !== null) {
                    let data = section.sections;
                    await dispatch('getNotNullNotes', {data, visitId, formId});
                }
            }
        }
        return true;
    },
    async updateNote({commit, rootState}, {visitId, formId, note}) {        
        let notation = rootState.visitStore.visitsFormsNotation;
        notation[visitId.toString()][formId.toString()].push(note);
        commit("StateVisitsFormsNotation", notation);
    },
    async formProgression({commit, dispatch, rootState}, visitId) {
        let progression = rootState.visitStore.visitsFormsProgression;
        let visitJson = rootState.visitStore.visit;
        progression[visitId.toString()] = [];
        for (let key in visitJson) {
            progression[visitId.toString()][visitJson[key].form_id.toString()] = [];
            progression[visitId.toString()][visitJson[key].form_id.toString()]['complete'] = 0;
            progression[visitId.toString()][visitJson[key].form_id.toString()]['total'] = 0;
            commit("StateVisitsFormsProgression", progression);
            let data = visitJson[key].sections;
            let formId = visitJson[key].form_id;
            dispatch('getCompletedSection', {data, visitId, formId});
        }
    },
    async getCompletedSection({dispatch}, {data, visitId, formId}) {
        if (typeof data === 'object' && data !== null) {
            for (const [, section] of Object.entries(data)) {
                let stateStection = 'total';
                if (typeof section.complete !== 'undefined' && section.complete !== null && section.complete === true) {
                    stateStection = 'complete';
                    await dispatch('updateCompletedSection', {visitId, formId, stateStection});
                }else {
                    await dispatch('updateCompletedSection', {visitId, formId, stateStection});
                }
                
                if (typeof section.sections === 'object' && section.sections !== null) {
                    let data = section.sections;
                    await dispatch('getCompletedSection', {data, visitId, formId});
                }
            }
        }
        return true;
    },
    updateCompletedSection({commit, rootState}, {visitId, formId, stateStection}) {
        let progression = rootState.visitStore.visitsFormsProgression;
        if(stateStection == 'complete') {
            progression[visitId.toString()][formId.toString()]['complete'] = progression[visitId.toString()][formId.toString()]['complete'] + 1;
        }
        progression[visitId.toString()][formId.toString()]['total'] = progression[visitId.toString()][formId.toString()]['total'] + 1;
        commit("StateVisitsFormsProgression", progression);
    }
}

const mutations = {
    StateVisits(state, visits) {
        state.visits = visits
    },
    StateVisit(state, visit) {
        state.visit = visit
    },
    StateVisitInfos(state, visitInfos) {
        state.visitInfos = visitInfos
    },
    StateSectionsIndex(state, sectionsIndex) {
        state.sectionsIndex = sectionsIndex
    },
    StateMine(state, mine) {
        state.mine = mine
    },
    StateCurrentFormId(state, currentFormId) {
        state.currentFormId = currentFormId
    },
    StateCurrentSectionsId(state, currentSectionsId) {
        state.currentSectionsId = currentSectionsId
    },
    StateCurrentStartedVisit(state, currentStartedVisitId) {
        state.currentStartedVisit = currentStartedVisitId
    },
    StateSeeHidden(state, seeHidden) {
        state.seeHidden = seeHidden
    },
    StateModeEditionVisit(state, modeEditionVisit) {
        state.modeEditionVisit = modeEditionVisit
    },
    StateFilesToSend(state, filesToSend) {
        state.filesToSend = filesToSend
    },
    StateVisitsFormsNotation(state, visitsFormsNotation) {
        state.visitsFormsNotation = visitsFormsNotation
    },
    StateVisitsFormsProgression(state, visitsFormsProgression) {
        state.visitsFormsProgression = visitsFormsProgression
    },
    LogOut(state) {
        state.visits = null;
        state.visit = null;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}